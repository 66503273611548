
export default {
    name: "TextToggleSwitch",

    props: {
        isOn: {
            type: Boolean,
            default: false,
        },
        onChange: {
            type: Function,
            default: () => { },
        },
        onText: {
            type: String,
            default: 'EIN',
        },
        offText: {
            type: String,
            default: 'AUS',
        },
    },
};
