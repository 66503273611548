export default function ({ store, route, redirect }) {
    if (process.server) {
        return
    }
    if (store.state.account.user) {
        if (route.name == 'index') {
            return redirect({ path: '/finance/dashboard/' })
        }
        return
    }
    const publicPages = [
        'user-mgmt',
        'maintenance',
        'signup',
        'signup-success',
        'aktie-share',
        'index'
    ]
    if (!route.name || publicPages.includes(route.name)) {
        return
    }
    return redirect({
        name: 'index',
    })
}
