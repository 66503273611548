
    import Alert from "@/components/Alert";
    import { backendExceptionHandler } from "@/mixins"
    import AuthService from '@/service/AuthService'

    export default {
        name: "ChangePassword",
        mixins: [backendExceptionHandler],
        components: {Alert},

        props: [
            'bus'
        ],

        data() {
            return {
                password: {
                    current: '',
                    new: '',
                    confirm: ''
                },
                errorMessage: "",
                isVisible: false,
                isLoading: false,
                success: false,
                rules: {
                    required: value => !!value || this.$t('common.validation.required'),
                },
            }
        },

        methods: {
            openWindow() {
                this.isVisible = true
            },

            closeWindow() {
                this.isVisible = false
                this.reset()
            },

            reset() {
                this.clearPassword()
                this.errorMessage = null
                this.success = false
            },

            async changePassword() {
                if(!this.validateForPasswordChange()) return

                this.isLoading = true

                try {
                    await AuthService.changePassword({
                        current: this.password.current,
                        new: this.password.new
                    })
                } catch (e) {
                    this.handleBackendException(e, e.message)
                    return
                } finally {
                    this.isLoading = false
                }
                this.success = true
                this.bus.$emit("auth-password-changed")
            },

            validateForPasswordChange() {
                for (const password in this.password) {
                    if(Object.prototype.hasOwnProperty.call(this.password, password)) {
                        if (this.password[password] != '') continue

                        this.errorMessage = this.$t('component.auth.changePassword.missingRequiredFields')
                        return false
                    }
                }

                if (this.arePasswordsEqual() !== true) {
                    this.errorMessage = this.$t('component.auth.changePassword.passwordsDoNotMatch')
                    return false
                }
                if (this.password.new.length < 6) {
                  this.errorMessage = this.$t('component.auth.changePassword.passwordMinLength')
                  return false
                }

                return true
            },

            clearPassword() {
                this.password = {
                    current: '',
                    new: '',
                    confirm: ''
                }
            },

            arePasswordsEqual() {
                if(!this.password.new || !this.password.confirm) {
                    return true
                }

                if(this.password.new === this.password.confirm) {
                    return true
                }

                return this.$t('component.auth.changePassword.passwordsDoNotMatch')
            },

            submitOnReturn(e) {
                if(e.key === "Enter" && this.isVisible) this.changePassword()
            },

            updateErrorMessage() {
                this.errorMessage = ""
            },
        },

        beforeMount() {
            this.bus.$on('request-password-change', this.openWindow)
            window.addEventListener('keydown', this.submitOnReturn)
        },

        beforeDestroy() {
            this.bus.$off('request-password-change', this.openWindow)
            window.removeEventListener('keydown', this.submitOnReturn)
        },

        watch: {
            bus(newBus, prevBus) {
                prevBus.$off('request-password-change', this.openWindow)
                newBus.$on('request-password-change', this.openWindow)
            }
        }
    }
