import User from '@/models/User'
import AuthService from '@/service/AuthService'
import CdnService from "./service/CdnService";

export const backendExceptionHandler = {
    methods: {
        /**
         * @param Error exception
         * @returns string A description of the error that exists after handling the exception, or null
         */
        handleBackendException: function(exception, errorContext = 'Backend-Fehler: ', updateMessageEvent = '') {
            if (exception.code == 'ECONNABORTED') {
                this.errorMessage = null
                return
            }
            if (exception.message === 'Network Error') {
                this.$root.$emit('network-error')
            }
            if (exception.code === 'ECONNABORTED' || exception.message === 'Network Error'
                    || this.$route?.name == 'index' && exception.response?.status == 401) {
                this.errorMessage = null
                return
            }
            if (exception?.response?.status === 404) {
                this.$nuxt.context.error({
                    statusCode: 404,
                    message: 'Wir haben nicht gefunden wonach du suchst',
                })
    
                return
            }

            let responseError = errorContext
            if (exception.response?.data.message || exception.response?.data.error) {
                responseError += ': ' + (exception.response?.data.message ?? exception.response?.data.error ?? '').toString()
            }
            this.$gtag?.exception({ description: responseError })
            if (exception?.response?.status === 426) {
                window.location.reload()
                return
            }
            if (exception.response?.status != 503) {
                if (updateMessageEvent) {
                    this.$emit(updateMessageEvent, responseError);
                }
                this.errorMessage = responseError
                return
            }
            this.$router.push({ name: 'maintenance', params: { originator: window.location.pathname, message: exception.response.data.message ?? null}})
            this.errorMessage = null
        },
    }
}

export const paginationStateHandler = {
    methods: {
        onPagination(e) {
            if (e.itemsPerPage !== this.itemsPerPage) {
                this.$store.commit('preferences/pageSizeSelection', {
                    name: this.$options.name, size: e.itemsPerPage
                })
            }
        }
    },

    created() {
        if (!this.itemsPerPage) {
            this.itemsPerPage = 100
        }
        this.itemsPerPage = this.$store.getters['preferences/getPageSize'](this.$options.name)
            || this.itemsPerPage
    },
}

export const sortHandler = {
    methods: {
        handleSortBy(){
            this.$nextTick(() => {
                this.sortDesc = true
            })
        }

    }
}

export const authStateHandler = {
    methods: {
        authStateChanged: function(user) {
            this.authInitialized = true
            if (user) {
                const providerId = user.providerData[0]?.providerId
                const preMessage = `Um Dich über ${providerId} anzumelden benötigst du`
                if (!user.email) {
                    this.$root.$emit(
                        'login-rejected',
                        `${preMessage} eine gültige eMail-Adresse`,
                    )
                } else if (user.providerData[0]?.providerId == 'password' && !user.emailVerified) {
                    this.$root.$emit(
                        'login-rejected',
                        `${preMessage} eine verifizierte eMail-Adresse`,
                    )
                } else {
                    this.$store.commit('account/login', new User(
                        user.uid,
                        user.email,
                    ))
                    try {
                        AuthService.ping(this.$axios)
                    } catch (e) {
                        console.log(e)
                    }
                    return
                }
            }
            if (this.$store.state.account.user?.preventLogout) {
                return  // workaround for e2e testing until we use Firebase emulator
            }
            this.$store.commit('account/setIsTwoFaEnabled', false)
            this.$store.commit('account/logout')
            //if (this.$route.matched.some(matched => matched.beforeEnter)) {
            if (this.$route.name && !['index', 'aktie-share', 'user-mgmt', 'signup', 'signup-success', 'maintenance'].includes(this.$route.name)) {
                // if it's an auth-protected page, redirect to login page
                this.$router.push({
                    name: 'index',
                    params: { redirectMessage: 'Du wurdest ausgeloggt' },
                })
            }
        }
    }
}

export const chartIntervalStateHandler = {
    methods: {
        changeInterval(interval) {
            this.$store.commit('preferences/activeChartInterval', interval)
        }
    },

    created() {
        if (!this.activeInterval) {
            this.activeInterval = 'day'
        } else {
            this.activeInterval = this.$store.getters['preferences/activeChartInterval']
        }
    },
}

export const companyLogoHandler = {
    methods: {
        replaceByDefaultLogo(e) {
            const element = e.target
            element.parentElement.classList.remove("company-logo")
            element.parentElement.classList.add("company-logo-invalid")
            e.target.src = require('@/assets/spatz-default.png')
        },

        getCompanyLogoUrl(logo) {
            if(!logo) return require('@/assets/spatz-default.png')
            return this.cdnService.getLogoUrl(logo);
        },

        getStockLogoClass(logo) {
            return (logo && logo != null) ? 'company-logo' : 'company-logo-invalid'
        },

        getStockLogoUrl(logo) {
            return this.cdnService.getStockLogoUrl(logo);
        },
        onLoadedLogo(e) {
            const element = e.target
            // remove invalid logo class
            if (!element.src.includes('assets/spatz-default.png')) {
                element.parentElement.classList.remove("company-logo-invalid")
                element.parentElement.classList.add("company-logo")
            }
        },

        getAssetMethodLogoUrl(logoName) {
            return require(`@/static/svg/asset_portfolio_types/${logoName}`)
        },
    },

    created() {
        this.cdnService = new CdnService()
    },
}

export const dividendYearSelectedStateHandler = {
    methods: {
        changeDividendYearSelected(year) {
            this.$store.commit('preferences/setDividendYearSelected', year)
        }
    }
}
