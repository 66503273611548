
    import UserService from "~/service/UserService";
    import FormatterService from "~/service/FormatterService";

    export default {
        props: { value: [Object, Boolean], standalone: Boolean },
        data() {
            return {
                formatter: new FormatterService()
            }
        },
        watch: {
            "$store.state.preferences.isPrivacyEnabled": {
                handler(value) {
                    this.formatter.updatePrivacyEnabled(value)
                },
                immediate: true
            }
        },
        methods: {
            async handleResponse(index) {
                try {
                    this.$gtag?.event(
                        'sp_usermessage_select',
                        { id: this.value.id, index: index }
                    );
                    await this.userService.postUserResponse(this.value.id, index);
                    this.$emit('input', null);
                    if (this.value.responseRoutes[index] !== '') {
                        this.$router.push({ name: (this.value.responseRoutes[index]) })
                    }
                } catch (error) {
                    console.error('Error sending response:', error);
                }
            }
        },
        created() {
            this.userService = new UserService(this.$axios);
        },
    }
