
    import Alert from "@/components/Alert";
    import { backendExceptionHandler } from "@/mixins"
    import AuthService from '@/service/AuthService'

    export default {
        name: "ChangeEmail",
        mixins: [backendExceptionHandler],
        components: {Alert},

        props: [
            'bus'
        ],

        data() {
            return {
                validForm: false,
                newEmail: null,
                password: null,
                errorMessage: null,
                isVisible: false,
                isLoading: false,
                success: false,
                rules: {
                    required: value => !!value || this.$t('common.validation.required'),
                },
            }
        },

        methods: {
            openWindow() {
                this.isVisible = true
            },

            closeWindow() {
                this.isVisible = false
                this.$nextTick(() => {
                    this.reset()
                })
            },

            async changeEmail() {
                this.$refs.form.validate()
                if (!this.validForm) {
                    return
                }
                this.isLoading = true
                try {
                    await AuthService.changeEmail(this.password, this.newEmail)
                } catch (e) {
                    console.log(e)
                    this.errorMessage = e.message
                    return
                } finally {
                    this.isLoading = false
                }
                this.success = true
            },

            submitOnEnter(e) {
                if(e.key === "Enter" && this.isVisible) {
                    this.changeEmail()
                }
            },

            reset() {
                this.newEmail = null
                this.errorMessage = null
                this.success = false
                this.password = null
                this.$refs.form?.reset()
            },
            updateErrorMessage() {
               this.errorMessage = ""
            }
        },

        beforeMount() {
            this.bus.$on('request-email-change', this.openWindow)
            window.addEventListener('keydown', this.submitOnEnter)
        },

        beforeDestroy() {
            this.bus.$off('request-email-change', this.openWindow)
            window.removeEventListener('keydown', this.submitOnEnter)
        },

        watch: {
            bus(newBus, prevBus) {
                prevBus.$off('request-email-change', this.openWindow)
                newBus.$on('request-email-change', this.openWindow)
            }
        }
    }
