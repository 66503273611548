
    export default {
        name: "Brand",

        computed: {
            logoPath() {
                return this.$store.state.theme.isDarkMode ?
                    require('@/assets/money-peak-logos/money-peak-white-logo.png') :
                    require('@/assets/money-peak-logos/money-peak-black-logo.png');
            },
            logoMobilePath() {
                return this.$store.state.theme.isDarkMode ?
                    require('@/assets/money-peak-full-logos/money-peak-full-white-logo-mobile.png') :
                    require('@/assets/money-peak-full-logos/money-peak-full-black-logo-mobile.png');
            },
        },

        data() {
            return {
                routeName: 'index'
            }
        },
    }
