
import Alert from "@/components/Alert";
import {backendExceptionHandler} from "@/mixins"
import AuthService from '@/service/AuthService'

export default {
    name: "DeleteAccount",
    mixins: [backendExceptionHandler],
    components: {Alert},

    props: ['bus'],

    data() {
        return {
            confirmDelete: false,
            errorMessage: "",
            isVisible: false,
            isLoading: false,
            success: false,
        };
    },

    beforeMount() {
        this.bus.$on("request-account-delete", this.openWindow);
    },

    methods: {
        openWindow() {
            this.isVisible = true;
        },

        closeWindow() {
            this.isVisible = false;
            this.reset();
        },

        reset() {
            this.confirmDelete = false;
            this.errorMessage = "";
            this.success = false;
        },

        async deleteUser() {
            if (!this.confirmDelete) {
                this.errorMessage = this.$t('component.auth.deleteAccount.confirmDelete');
                return;
            }

            this.isLoading = true;

            try {
                await AuthService.deleteAccount(this.$axios);
                this.success = true;
                this.bus.$emit("auth-account-deleted");
            } catch (e) {
                this.handleBackendException(e, e.message);
            } finally {
                this.reset();
                this.isLoading = false;
                this.isVisible = false;
                this.$store.commit('account/logout')
                await this.$router.push({
                    name: 'index',
                    params: {redirectMessage: this.$t('component.auth.deleteAccount.redirectMessage')},
                })
            }
        },

        updateErrorMessage() {
            this.errorMessage = ""
        },
    },


    beforeUnmount() {
        this.bus.$off("request-account-delete", this.openWindow);
    },
}
