import { render, staticRenderFns } from "./PortfolioAssetTypeSelector.vue?vue&type=template&id=65ab7748&scoped=true&"
import script from "./PortfolioAssetTypeSelector.vue?vue&type=script&lang=js&"
export * from "./PortfolioAssetTypeSelector.vue?vue&type=script&lang=js&"
import style0 from "./PortfolioAssetTypeSelector.vue?vue&type=style&index=0&id=65ab7748&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ab7748",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Alert: require('/builds/spatz/ui/web/spatz-app/components/Alert.vue').default})
