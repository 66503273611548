
import Alert from "@/components/Alert";
import {backendExceptionHandler} from "@/mixins";
import AuthService from "@/service/AuthService";
import QRCode from 'qrcode';


export default {
    name: "EnableTwoFactorAuthentication",
    mixins: [backendExceptionHandler],
    inject: ['closeMenu'],
    components: {Alert},
    
    data() {
        return {
            dialogVisible: false,
            validForm: false,
            isLoading: false,
            success: false,
            codeSend: false,
            twoFaCode: null,
            qrCodeImage: null,
            qrCodeUri: null,
            errorMessage: '',
            infoMessage: '',
            rules: {
                required: value => !!value || this.$t('component.enableTwoFactorAuthentication.fieldRequired'),
            },
            isReauthenticationFailed: false,
        };
    },

    methods: {
        async handleClickReauthenticate() {
            try {
                await AuthService.logout()
                this.$store.commit('account/logout')
                await this.$router.push({
                    name: 'index',
                    params: { redirectMessage: this.$t('common.logoutSuccess') },
                })
            } catch(e) {
                console.log(e)
            }
        },
        async startEnableTwoFaLogin() {
            try {
                this.isLoading = true;
                await AuthService.startEnableTwoFaAuthentication().then(async (response) => {
                    this.qrCodeUri = response
                    this.qrCodeImage = await QRCode.toDataURL(response);
                })
                this.codeSend = true;
            } catch (error) {
                if (error.message === 'auth/requires-recent-login') {
                    const credential = this.$store.state.account.credential
                    if (credential) {
                        await this.reauthenticateUser(credential)
                    } else {
                        this.errorMessage = this.handleAuthError(error);
                    }
                } else {
                    this.errorMessage = this.handleAuthError(error);
                }
            } finally {
                this.isLoading = false;
            }
        },
        
        async reauthenticateUser(credential) {
            try {
                await AuthService.reauthenticateWithCredential(credential).then((response) => {
                    if (response && response.operationType === 'reauthenticate') {
                        return this.startEnableTwoFaLogin()
                    }
                })
            } catch (error) {
                this.isReauthenticationFailed = true
                this.errorMessage = this.$t('component.enableTwoFactorAuthentication.error.failedReauthentication');
            }
        },
        
        async completeEnableTwoFaLogin() {
            this.$refs.form.validate()
            if (!this.validForm) {
                return
            }
            try {
                await AuthService.completeEnableTwoFaAuthentication(this.twoFaCode);
                this.infoMessage = this.$t('component.enableTwoFactorAuthentication.successMessage');
                this.success = true;
            } catch (error) {
                if (error.message === 'auth/invalid-verification-code') {
                    this.errorMessage = this.$t('component.twoFactor.error.wrongCode')
                } else {
                    this.errorMessage = error.message;
                }
            }
        },
        openDialog() {
            this.startEnableTwoFaLogin()
            this.closeMenu()
            this.dialogVisible = true;
        },
        closeDialog() {
            this.dialogVisible = false;
            if (this.success) {
                this.$store.commit('account/setIsTwoFaEnabled', true);
            }
            this.reset();
        },
        reset() {
            this.errorMessage = '';
            this.success = false;
            this.infoMessage = '';
            this.codeSend = false;
            this.twoFaCode = null;
            this.qrCodeImage = null;
            this.qrCodeUri = null;
            this.isReauthenticationFailed = false;
        },
        handleAuthError(error) {
            if (error.message === 'auth/second-factor-already-in-use') {
                return this.$t('component.twoFactor.error.alreadyActivated');
            } else if (error.code === 'auth/requires-recent-login' || error.message === 'auth/requires-recent-login') {
                return this.$t('component.twoFactor.error.wrongCode');
            } else {
                return error.message;
            }
        },
        updateErrorMessage() {
            this.errorMessage = ''
        },
    },
};
