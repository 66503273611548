const state = () => ({
    isDarkMode: false,
});

const mutations = {
    toogleTheme(state) {
        state.isDarkMode = !state.isDarkMode;
    },
};

export default {
    state,
    mutations,
}
