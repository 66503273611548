import Vue from 'vue';
import de from '~/locales/de.json';
import en from '~/locales/en.json';

const FALLBACK_LOCALE = 'de';
const AVAILABLE_LOCALES = [
    { code: 'de', name: 'Deutsch', flag: 'de.png', translations: de },
    { code: 'en', name: 'English', flag: 'en.png', translations: en }
];

const getTranslationsForLocale = (locale) => {
    const localeData = AVAILABLE_LOCALES.find(l => l.code === locale);
    return localeData ? localeData.translations : null;
};

const detectBrowserLanguage = () => {
    if (process.client) {
        const language = navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;

        return language ? language.substring(0, 2) : FALLBACK_LOCALE;
    }
    return FALLBACK_LOCALE;
};

const getAvailableLocales = () => {
    return AVAILABLE_LOCALES;
};

const getFallbackLocale = () => {
    return FALLBACK_LOCALE;
};

const setLocale = (locale, store) => {
    const localeCode = AVAILABLE_LOCALES.map(l => l.code).includes(locale) ? locale : FALLBACK_LOCALE;
    store.commit('preferences/setLocale', localeCode);
};

const getCurrentLocale = (store, route) => {
    const language = route.query.lang || store.getters['preferences/getLocale'] || detectBrowserLanguage();
    return AVAILABLE_LOCALES.some(locale => locale.code === language) ? language : FALLBACK_LOCALE;
};

const translate = (key, options = {}, locale, store, route) => {
    const currentLocale = locale || getCurrentLocale(store, route);
    const translations = getTranslationsForLocale(currentLocale);  // Get translations dynamically

    if (!translations || !key || typeof key !== 'string') {
        return key;
    }

    let translation = translations[key];

    if (!translation) {
        return key;
    }

    translation = translation.replace(/{(\w+)}/g, (match, p1) => {
        return options[p1] !== undefined ? options[p1] : match;
    });

    return translation;
};

export default (context) => {
    Vue.prototype.$t = (key, options = {}, locale) => translate(key, options, locale, context.store, context.route);
    Vue.prototype.$setLocale = (locale) => setLocale(locale, context.store);
    Vue.prototype.$detectBrowserLanguage = detectBrowserLanguage;
    Vue.prototype.$getCurrentLocale = () => getCurrentLocale(context.store, context.route);
    Vue.prototype.$getFallbackLocale = getFallbackLocale;
    Vue.prototype.$getAvailableLocales = getAvailableLocales;
};
