
    import Searchbar from "@/components/Searchbar";
    import AuthMenu from "@/components/auth/Menu";
    import Brand from "./Brand";
    import PortfolioTabs from "./portfolio/PortfolioTabs";
    import { Icon } from '@iconify/vue2';
    import HelpCenter from "~/components/HelpCenter";
    import TextToggleSwitch from "@/components/shared/TextToggleSwitch";

    export default {
        name: "SiteHeader",

        components: {
            Brand,
            AuthMenu,
            Searchbar,
            PortfolioTabs,
            Icon,
            HelpCenter,
            TextToggleSwitch,
        },
        computed: {
            inFeedbackPage() {
                return this.$route.name == 'feedback'
            },
            isLoggedIn() {
                return !!this.$store.state.account.user
            },
            portfolioId() {
                return this.$route.name?.indexOf('finance-portfolio') !== -1
                    ? this.$route.params.portfolio_id
                    : '-1'
            },
            isMobile: {
                get() { return this.$vuetify.breakpoint.xsOnly },
            },
            isPrivacyEnabled() {
                return this.$store.state.preferences.isPrivacyEnabled
            }
        },
        methods: {
            handleTogglePrivacy() {
                this.$store.commit('preferences/setIsPrivacyEnabled', !this.isPrivacyEnabled);
            },
        },
    }
