
import Alert from "@/components/Alert";
import {backendExceptionHandler} from "@/mixins";
import AuthService from "@/service/AuthService";

export default {
    name: "DisableTwoFactorAuthentication",
    mixins: [backendExceptionHandler],
    inject: ['closeMenu'],
    components: {Alert},

    data() {
        return {
            dialogVisible: false,
            isLoading: false,
            success: false,
            errorMessage: '',
            infoMessage: '',
        };
    },

    methods: {
        async disableMfa() {
            try {
                await AuthService.disableMfa(this.$axios);
                this.infoMessage = this.$t('component.disableTwoFactorAuthentication.successMessage')
                this.success = true;
            } catch (error) {
                if (error.code === 'auth/requires-recent-login') {
                    const credential = this.$store.state.account.credential
                    if (credential) {
                        await this.reauthenticateUser(credential)
                    } else {
                        this.errorMessage = this.handleAuthError(error);
                    }
                }
                this.errorMessage = this.handleAuthError(error);
            }
        },
        async reauthenticateUser(credential) {
            try {
                await AuthService.reauthenticateWithCredential(credential).then((response) => {
                    if (response && response.operationType === 'reauthenticate') {
                        return this.disableMfa()
                    }
                })
            } catch (error) {
                this.errorMessage = this.handleAuthError(error)
            }
        },
        openDialog() {
            this.closeMenu()
            this.dialogVisible = true;
        },
        closeDialog() {
            this.dialogVisible = false;
            if (this.success) {
                this.$store.commit('account/setIsTwoFaEnabled', false);
            }
            this.reset();
        },
        reset() {
            this.errorMessage = '';
            this.success = false;
            this.infoMessage = '';
            this.dialogVisible = false;
        },
        handleAuthError(error) {
            if (error.code === 'auth/second-factor-already-in-use') {
                return this.$t('component.twoFactor.error.alreadyActivated');
            } else if (error.code === 'auth/requires-recent-login') {
                return this.$t('component.twoFactor.error.failedReauthentication');
            } else {
                return error.message;
            }
        },
        updateErrorMessage() {
            this.errorMessage = ''
        },
    },

};
