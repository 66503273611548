
import FormatterService from "~/service/FormatterService";
import UserService from "~/service/UserService";
import UserMessage from "@/components/UserMessage";
import { backendExceptionHandler } from "@/mixins";

export default {
    mixins: [ backendExceptionHandler ],
    components: { UserMessage },
    data() {
        return {
            tableDialog: false,
            page: 1,
            messages: [],
            errorMessage: null,
            detail: null,
            headers: [
                { text: this.$t('component.userMessages.header.type'), value: 'type', width: 150 },
                { text: this.$t('component.userMessages.header.regarding'), value: 'title' },
                { text: this.$t('component.userMessages.header.date'), value: 'created', align: 'end' },
            ],
            formatter: new FormatterService(),
        }
    },
    computed: {
        nUnread() {
            return this.messages.reduce(
                (prev, curr) => prev + (curr.isRead ? 0 : 1),
                0
            );
        },
    },
    methods: {
        onDismiss() {
            if (this.detail) {
                this.detail = null;
            } else {
                this.tableDialog = false;
            }
        },
        async messageClicked(item) {
            this.detail = item;
            if (this.detail.isRead) {
                return;
            }
            try {
                this
                    .userService
                    .markAsRead(this.detail.id)
                    .then(this.loadMessages)
            } catch (error) {
                this.handleBackendException(
                    error,
                    this.$t('component.userMessages.messageClickError')
                );
                this.tableDialog = false
            }
        },
        getMessageClass(item) {
            return 'message-row' + (item.isRead ? '' : ' unread');
        },
        async loadMessages() {
            try {
                this.messages = await this.userService.getMessages();
            } catch (error) {
                this.handleBackendException(
                    error,
                    this.$t('component.userMessages.loadMessageError')
                );
            }
        },
    },
    mounted() {
        this.loadMessages();
    },
    created() {
        this.userService = new UserService(this.$axios);
        this.$root.$on('user-message-read', this.loadMessages);
    },
    beforeDestroy() {
        this.$root.$off('user-message-read');
    },
    watch: {
        tableDialog() {
            if (this.tableDialog) {
                this.detail = null;
                this.page = 1;
                this.errorMessage = null;
                this.loadMessages();
            }
        },
        $route() {
            this.tableDialog = false;
        },
        "$store.state.preferences.isPrivacyEnabled": {
            handler(value) {
                this.formatter.updatePrivacyEnabled(value)
            },
            immediate: true
        }
    },
}
