class PortfolioService {
    lastError;
    lastResponseCode;
    currentRequest = {};

    constructor(axios) {
        this.axios = axios
    }

    async getConnectedBanks() {

        const url = '/api/finapi/connected_banks'
        return await this
            .axios
            .get(url)
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async updateIntoPortfolio(portfolioName, connectionId) {
        const url = '/api/finapi/update_into_portfolio'
        return await this
            .axios
            .post(url, {
                portfolioName,
                connectionId,
            })
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async updateBankConnection(portfolioId) {
        const url = '/api/finapi/update_connection'
        return await this
            .axios
            .post(url, { portfolioId })
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async startFinBankConnectionImport(portfolioName) {
        const url = '/api/finapi/import_bank_connection'
        return await this
            .axios
            .post(url, { portfolioName })
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async rename(portfolioId, name) {
        const url = '/api/portfolio/'+portfolioId
        const data = {
            name: name
        }
        return await this
            .axios
            .put(url, data)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async changeCurrency(portfolio, newCurrency) {
        const url = portfolio
            ? `/api/portfolio/${portfolio.id}`
            : '/api/account'
        const data = {
            currency: newCurrency,
        }
        if (portfolio) {
            data.name = portfolio.name
        }

        return await this
            .axios
            .put(url, data)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async getAccount() {
        const url = '/api/account'

        return await this
            .axios
            .get(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async archive(portfolioId) {
        let url = "/api/portfolio/" + portfolioId

        return await this
            .axios
            .delete(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async hardDeletePortfolio(portfolioId) {
        let url = `/api/portfolio/${portfolioId}/hard`

        return await this
            .axios
            .delete(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async updatePosition(portfolioId, positionId, updateData) {
        if(!portfolioId) throw new Error("portfolio id is mandatory")
        if(!positionId) throw new Error("position id is mandatory")
        if(!updateData) throw new Error("cant change no data")

        const url = '/api/portfolio/'+portfolioId+'/position/'+positionId

        return await this
            .axios
            .put(url, updateData)
            .then((res) => {
                this.lastResponseCode = res.status
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async undeletePortfolio(portfolioId) {
        if(!portfolioId) throw new Error("portfolio id is mandatory")

        const url = '/api/portfolio/'+portfolioId+'/undelete'

        return await this
            .axios
            .put(url)
            .then((res) => {
                this.lastResponseCode = res.status
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async queryAll(interval = 'max')
    {
        const url = `/api/portfolios?period=${interval}`
        if (this.currentRequest.queryAll) {
            await this.currentRequest.queryAll.cancel()
        }
        const axiosSource = this.axios.CancelToken.source()
        this.currentRequest.queryAll = { cancel: axiosSource.cancel }

        return await this
            .axios
            .get(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                if (this.axios.isCancel(error)) {
                    return null
                }
                return this.handleError(error)
            })
    }

    async queryDeleted()
    {
        const url = '/api/portfolios/deleted'

        return await this
            .axios
            .get(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async queryPositions(portfolioId, interval)
    {
        if (this.currentRequest.queryPositions) {
            await this.currentRequest.queryPositions.cancel()
        }
        const axiosSource = this.axios.CancelToken.source()
        this.currentRequest.queryPositions = { cancel: axiosSource.cancel }
        const url = `/api/v2/portfolio/${portfolioId}/positions?period=${interval}`
        
        return await this
            .axios
            .get(url, { cancelToken: axiosSource.token })
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch(error => {
                if (this.axios.isCancel(error)) {
                    return null
                }
                return this.handleError(error)
            })
    }

    async queryPortfolio(portfolioId, interval = 'max')
    {
        if (this.currentRequest.queryPortfolio) {
            await this.currentRequest.queryPortfolio.cancel()
        }
        const axiosSource = this.axios.CancelToken.source()
        this.currentRequest.queryPortfolio = { cancel: axiosSource.cancel }
        const url = `/api/portfolio/${portfolioId}`
        return await this
            .axios
            .get(url, {
                params: {
                    period: interval,
                },
                cancelToken: axiosSource.token,
            })
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch(error => {
                if (this.axios.isCancel(error)) {
                    return null
                }
                return this.handleError(error)
            })
    }

    async getTabs() {
        const url = '/api/portfolio_tabs'

        return await this
            .axios
            .get(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async queryTotals(portfolioId, period) {
        if (this.currentRequest.queryTotals) {
            await this.currentRequest.queryTotals.cancel()
        }
        const axiosSource = this.axios.CancelToken.source();
        this.currentRequest.queryTotals = { cancel: axiosSource.cancel }
        const url = '/api/portfolio/totals' + (portfolioId
            ? `/${portfolioId}`
            : ''
        ) + `?period=${period}`

        return await this
            .axios
            .get(url, { cancelToken: axiosSource.token})
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                if (this.axios.isCancel(error)) {
                    return {}
                }
                return this.handleError(error)
            })
    }

    async getPortfolioStat(portfolioId, stat) {
        const url = `/api/portfoliostat/${stat}` + (portfolioId
            ? `/${portfolioId}`
            : ''
        )

        return await this
            .axios
            .get(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async setPositions(orderedPortfolioIds) {

        let  url = '/api/portfolio/setsort'     

        const data = {
            ids: orderedPortfolioIds
        }

        return await this
            .axios      
            .post(url, data)
            .then((res) => {                      
                this.lastResponseCode = res.status
                return res.data
            })                 
            .catch((error) => {               
                return this.handleError(error)
            })
    }

    async makePublic(portfolioId) {
        const url = `/api/portfolio/publish/${portfolioId}`
        return await this
            .axios
            .post(url)
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch(error => {
                return this.handleError(error)
            })
    }

    async makePrivate(portfolioId) {
        const url = `/api/portfolio/unpublish/${portfolioId}`
        return await this
            .axios
            .post(url)
            .then(res => {
                this.lastResponseCode = res.status
                return null
            })
            .catch(error => {
                return this.handleError(error)
            })
    }

    async link(portfolioId) {
        const url = `/api/portfolio/link/${portfolioId}`
        return await this
            .axios
            .post(url)
            .then(res => {
                this.lastResponseCode = res.status
                return null
            })
            .catch(error => {
                return this.handleError(error)
            })
    }

    async unlink(portfolioId) {
        const url = `/api/portfolio/unlink/${portfolioId}`
        return await this
            .axios
            .post(url)
            .then(res => {
                this.lastResponseCode = res.status
                return null
            })
            .catch(error => {
                return this.handleError(error)
            })
    }

    async fetchPerformance(portfolioId) {
        let url = '/api/portfolio_performance'
        if (portfolioId) {
            url += `/${portfolioId}`
        }
        return await this
            .axios
            .get(url)
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch(error => {
                return this.handleError(error)
            })
    }

    async exportTransactions(portfolioId) {
        const url = `/api/portfolio/export/${portfolioId}/csv`
        return await this
            .axios
            .get(url)
            .then(res => {
                this.lastResponseCode = res.status
                return {
                    filename: res['headers']['content-disposition'].match('filename="([^"]*)"')[1],
                    contents: res.data,
                }
            })
            .catch(error => {
                return this.handleError(error)
            })
    }

    async getAssetMethodsPortfolio() {
        const url = '/api/assetGroup'
        return await this
            .axios
            .get(url)
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async createAssetPortfolio(data) {
        const url = '/api/portfolio'
        return await this
            .axios
            .post(url, {
                ...data
            })
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async getPositionsColumns(portfolioId) {
        const url = `/api/portfolio/columns/${portfolioId}`;

        return await this
            .axios
            .get(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            })
    }

    async updatePortfolioColumns(portfolioId, data) {
        const url = `/api/portfolio/columns/${portfolioId}`
        return await this
            .axios
            .put(url, data)
            .then(res => {
                this.lastResponseCode = res.status
                return null
            })
            .catch(error => {
                return this.handleError(error)
            })
    }

    async resetPortfolioColumns(portfolioId) {
        const url = `/api/portfolio/columns/${portfolioId}/reset`
        return await this
            .axios
            .get(url)
            .then(res => {
                this.lastResponseCode = res.status
                return null
            })
            .catch(error => {
                return this.handleError(error)
            })
    }

    async querySelectionPositions(portfolioId, interval) {
        if (this.currentRequest.querySelectionPositions) {
            await this.currentRequest.querySelectionPositions.cancel()
        }
        const axiosSource = this.axios.CancelToken.source()
        this.currentRequest.querySelectionPositions = { cancel: axiosSource.cancel }
        const url = `/api/portfolio/${portfolioId}/positions/select-values?period=${interval}`
        return await this
            .axios
            .get(url, { cancelToken: axiosSource.token })
            .then(res => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch(error => {
                if (this.axios.isCancel(error)) {
                    return null
                }
                return this.handleError(error)
            })
    }

    handleError(e) {
      console.log(e)

      this.lastResponseCode = e.response?.status || e.code
      this.lastError = e.response?.statusText || e.message

      throw e
    }
}

export default PortfolioService
