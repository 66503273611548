import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e2c39f3c = () => interopDefault(import('../pages/aktie.vue' /* webpackChunkName: "pages/aktie" */))
const _3ce602c0 = () => interopDefault(import('../pages/aktie/_share/index.vue' /* webpackChunkName: "pages/aktie/_share/index" */))
const _17668212 = () => interopDefault(import('../pages/aktie/_share/fundamentaldaten/_tab_id.vue' /* webpackChunkName: "pages/aktie/_share/fundamentaldaten/_tab_id" */))
const _2cc815a6 = () => interopDefault(import('../pages/feature-portfolio-automatisch-importieren.vue' /* webpackChunkName: "pages/feature-portfolio-automatisch-importieren" */))
const _3f42ac79 = () => interopDefault(import('../pages/feedback.vue' /* webpackChunkName: "pages/feedback" */))
const _55f38dcf = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _311135e2 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _719af53f = () => interopDefault(import('../pages/user-mgmt.vue' /* webpackChunkName: "pages/user-mgmt" */))
const _064432b1 = () => interopDefault(import('../pages/checkout/success/index.vue' /* webpackChunkName: "pages/checkout/success/index" */))
const _62ec7205 = () => interopDefault(import('../pages/feature/individuelle-assets.vue' /* webpackChunkName: "pages/feature/individuelle-assets" */))
const _75afa05b = () => interopDefault(import('../pages/finance/dashboard.vue' /* webpackChunkName: "pages/finance/dashboard" */))
const _509562f0 = () => interopDefault(import('../pages/finance/dashboard/_tab_id.vue' /* webpackChunkName: "pages/finance/dashboard/_tab_id" */))
const _764d410f = () => interopDefault(import('../pages/finance/portfolio.vue' /* webpackChunkName: "pages/finance/portfolio" */))
const _6ae28052 = () => interopDefault(import('../pages/finance/portfolio/index.vue' /* webpackChunkName: "pages/finance/portfolio/index" */))
const _5fb91434 = () => interopDefault(import('../pages/finance/portfolio/_portfolio_id/_tab_id.vue' /* webpackChunkName: "pages/finance/portfolio/_portfolio_id/_tab_id" */))
const _eb0b3300 = () => interopDefault(import('../pages/signup/success.vue' /* webpackChunkName: "pages/signup/success" */))
const _16038c9f = () => interopDefault(import('../pages/checkout/create/_product_id/index.vue' /* webpackChunkName: "pages/checkout/create/_product_id/index" */))
const _1439d70e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktie/",
    component: _e2c39f3c,
    pathToRegexpOptions: {"strict":true},
    name: "aktie",
    children: [{
      path: ":share?/",
      component: _3ce602c0,
      pathToRegexpOptions: {"strict":true},
      name: "aktie-share"
    }, {
      path: ":share?/fundamentaldaten/:tab_id?/",
      component: _17668212,
      pathToRegexpOptions: {"strict":true},
      name: "aktie-share-fundamentaldaten-tab_id"
    }]
  }, {
    path: "/feature-portfolio-automatisch-importieren/",
    component: _2cc815a6,
    pathToRegexpOptions: {"strict":true},
    name: "feature-portfolio-automatisch-importieren"
  }, {
    path: "/feedback/",
    component: _3f42ac79,
    pathToRegexpOptions: {"strict":true},
    name: "feedback"
  }, {
    path: "/maintenance/",
    component: _55f38dcf,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance"
  }, {
    path: "/signup/",
    component: _311135e2,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/user-mgmt/",
    component: _719af53f,
    pathToRegexpOptions: {"strict":true},
    name: "user-mgmt"
  }, {
    path: "/checkout/success/",
    component: _064432b1,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-success"
  }, {
    path: "/feature/individuelle-assets/",
    component: _62ec7205,
    pathToRegexpOptions: {"strict":true},
    name: "feature-individuelle-assets"
  }, {
    path: "/finance/dashboard/",
    component: _75afa05b,
    pathToRegexpOptions: {"strict":true},
    name: "finance-dashboard",
    children: [{
      path: ":tab_id?/",
      component: _509562f0,
      pathToRegexpOptions: {"strict":true},
      name: "finance-dashboard-tab_id"
    }]
  }, {
    path: "/finance/portfolio/",
    component: _764d410f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _6ae28052,
      pathToRegexpOptions: {"strict":true},
      name: "finance-portfolio"
    }, {
      path: ":portfolio_id/:tab_id?/",
      component: _5fb91434,
      pathToRegexpOptions: {"strict":true},
      name: "finance-portfolio-portfolio_id-tab_id"
    }]
  }, {
    path: "/signup/success/",
    component: _eb0b3300,
    pathToRegexpOptions: {"strict":true},
    name: "signup-success"
  }, {
    path: "/checkout/create/:product_id?/",
    component: _16038c9f,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-create-product_id"
  }, {
    path: "/",
    component: _1439d70e,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
