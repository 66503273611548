

    export default {
        name: "SiteFooter",
        data() {
            return {
                showLoginPrompt: false,
            };
        },
        computed: {
            logoPath() {
                return this.$store.state.theme.isDarkMode ?
                    require('@/assets/money-peak-full-logos/money-peak-full-white-logo.png') :
                    require('@/assets/money-peak-full-logos/money-peak-full-black-logo.png');
            },
            xLogoPath() {
                return this.$store.state.theme.isDarkMode ?
                    require('@/assets/social_logos/x-logo-dark.svg') :
                    require('@/assets/social_logos/x-logo-light.svg');
            },
            discordLogoPath() {
                return this.$store.state.theme.isDarkMode ?
                    require('@/assets/social_logos/discord-logo-dark.svg') :
                    require('@/assets/social_logos/discord-logo-light.svg');
            },
        },
        methods: {
            isLoggedIn() {
                return !!this.$store.state.account.user;
            },
            closeWindow() {
                this.showLoginPrompt = false;
            },
            handleFeedbackClick() {
                if (!this.isLoggedIn()) {
                    this.showLoginPrompt = true;
                } else {
                    this.$router.push('/feedback/');
                }
            },
        },
    }
