
import AuthService from "@/service/AuthService";

export default {
    name: "LocaleSelector",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedLocale: this.$store.getters['preferences/getLocale'] || this.$getFallbackLocale(),
            availableLocales: this.$getAvailableLocales(),
        };
    },
    computed: {
        localeChanged() {
            return this.selectedLocale !== this.$getCurrentLocale();
        }
    },
    methods: {
        selectLocale(locale) {
            this.selectedLocale = locale;
        },
        async applyLocale() {
            this.$vuetify.lang.current = this.selectedLocale;
            this.$setLocale(this.selectedLocale);

            try {
                await AuthService.updateUserLanguage(this.selectedLocale, this.$axios, this.$t);
            } catch (error) {
                console.error("Failed to update language:", error);
            }

            this.$emit('input', false);
        },
        closeModal() {
            this.$emit('input', false);
        },
        onDialogInput(event) {
            this.$emit('input', event);
        },
    }
};
