
import Alert from "@/components/Alert";
import {backendExceptionHandler} from "@/mixins";
import ConfigService from "@/service/ConfigService";

export default {
    name: "SignalConfigurationDialog",
    mixins: [backendExceptionHandler],
    inject: ['closeMenu'],
    components: {Alert},

    data() {
        return {
            dialogVisible: false,
            isLoading: false,
            errorMessage: '',
            infoMessage: '',
            configs: null,
            debounceTimeout: null,
            validForm: false,
            isInitializing: false,
        };
    },
    computed: {
        headlines: function () {
            return [
                {name: this.$t('component.signalConfigurationDialog.portfolio'), align: 'left', helperClass: 'ml-3'},
                {name: this.$t('component.signalConfigurationDialog.maxLoss'), align: 'right', helperClass: 'mr-9'},
                {name: this.$t('component.signalConfigurationDialog.maxProfit'), align: 'right', helperClass: 'mr-10'},
                {name: this.$t('component.signalConfigurationDialog.notification'), align: 'left', helperClass: ''}
            ]
        },
        notificationPreferences: function () {
            return [
                {name: this.$t('component.signalConfigurationDialog.systemMessage'), value: 'message'},
                {name: this.$t('component.signalConfigurationDialog.email'), value: 'email'},
                {name: this.$t('component.signalConfigurationDialog.both'), value: 'all'},
                {name: this.$t('component.signalConfigurationDialog.none'), value: 'none'}
            ]
        },
        rules: function () {
            return {
                required: value => !!value || this.$t('component.signalConfigurationDialog.fieldRequired'),
                isNumeric: value => !isNaN(parseFloat(value)) && isFinite(value) || this.$t('component.signalConfigurationDialog.numericOnly'),
                minValue: value => value >= 1 || 'Der Wert ist zu klein'
            }
        },
        tooltipContent: function () {
            return {
                [this.$t('component.signalConfigurationDialog.maxLoss')]: this.$t('component.signalConfigurationDialog.maxLossTooltip'),
                [this.$t('component.signalConfigurationDialog.maxProfit')]: this.$t('component.signalConfigurationDialog.maxProfitTooltip')
            }
        }
    },

    methods: {
        async fetchSettings() {
            this.isLoading = true
            this.isInitializing = true
            await this.configService.getSignalCongfig().then((response) => {
                if (response) {
                    this.configs = response.data ?? null
                }
            }).catch((error) => {
                this.handleError(error)
            }).finally(() => {
                this.isLoading = false
                this.isInitializing = false
            });
        },
        async openDialog() {
            this.reset()
            this.closeMenu()
            this.configService = new ConfigService(this.$axios)
            this.dialogVisible = true;
            await this.fetchSettings()
        },
        closeDialog() {
            this.dialogVisible = false;
            this.reset();
        },
        reset() {
            this.errorMessage = '';
            this.infoMessage = '';
            this.dialogVisible = false;
            this.configs = null;
        },
        updateErrorMessage() {
            this.errorMessage = ''
        },
        updateInfoMessage() {
            this.infoMessage = ''
        },

        updateConfigValue(index, key) {
            if (!this.configs || !this.configs[index]) {
                console.warn("Invalid config index:", index);
                return;
            }
            const newValue = this.configs[index][key];
            if (key !== 'notificationPreference') {
                this.$refs.form.validate()
                if (!this.validForm) {
                    return
                }
                this.configs[index][key] = isNaN(parseFloat(newValue)) ? null : parseFloat(newValue);
            } else {
                this.configs[index][key] = newValue;
            }
        },

        debounceUpdateConfigValue(index, key) {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.updateConfigValue(index, key);
            }, 500);
        },

        async submitChanges() {
            this.$refs.form.validate()
            if (!this.validForm) {
                return
            }
            this.isLoading = true
            
            const mappedConfigs = this.configs.map((config) => ({
                id: config.id,
                maxLossPct: parseFloat(config.maxLossPct),
                maxProfitPct: parseFloat(config.maxProfitPct),
                notificationPreference: config.notificationPreference,
            }));

            await this.configService.updateSignalConfig(mappedConfigs)
                .then((response) => {
                    if (response?.status === 200) {
                        this.infoMessage = this.$t('component.signalConfigurationDialog.configSaved')
                        this.resetUpdateStatus()
                    }
                    if (response?.status !== 200 && response?.data?.message) {
                        this.errorMessage = response.data.message
                    }
                }).catch((error) => {
                    this.handleError(error)
                }).finally(() => {
                    this.isLoading = false
                })
        },
        redirectToPortfolio(id) {
            this.closeDialog()
            this.$router.push({
                name: 'finance-portfolio-portfolio_id-tab_id',
                params: {
                    portfolio_id: id.toString(),
                }
            })  
        },
        resetUpdateStatus() {
            this.configs.forEach(config => {
                config.updated = false;
            });
        },
        handleError(error) {
            if (error.message === 'Request failed with status code 500') {
                this.errorMessage = this.$t('component.signalConfigurationDialog.error')
            } else {
                this.errorMessage = error.message
            }
        }
    },
};
