import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=68c473a0&scoped=true&"
import script from "./Menu.vue?vue&type=script&lang=js&"
export * from "./Menu.vue?vue&type=script&lang=js&"
import style0 from "./Menu.vue?vue&type=style&index=0&id=68c473a0&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68c473a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HelpCenter: require('/builds/spatz/ui/web/spatz-app/components/HelpCenter.vue').default,SignalConfigurationDialog: require('/builds/spatz/ui/web/spatz-app/components/SignalConfigurationDialog.vue').default,LocaleSelector: require('/builds/spatz/ui/web/spatz-app/components/LocaleSelector.vue').default,UserMessageDialog: require('/builds/spatz/ui/web/spatz-app/components/UserMessageDialog.vue').default})
