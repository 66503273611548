
    import draggable from 'vuedraggable'
    import PortfolioService from "../../service/PortfolioService";
    import { backendExceptionHandler } from '@/mixins'
    import FormatterService from '@/service/FormatterService';
    import subscriptionConstants from '@/constants/subscription.constants'
    import PortfolioTab from '@/components/portfolio/PortfolioTab'
    import MobileDialog from '@/components/shared/MobileDialog'

    const { subscribeProductKeys } = subscriptionConstants

    export default {
        name: "PortfolioTabs",
        mixins: [backendExceptionHandler],

        components: {
            draggable,
            PortfolioTab,
            MobileDialog
        },

        props: {
            portfolioId: String
        },

        data() {
            return {
                portfolios: [],
                portfolioTab: null,
                errorMessage: null,
                showArrows: true,
                sliderKey: true,
                assetMethodsIcons: null,
                showMorePortfolios: false,
                visiblePortfolios: [],
                invisiblePortfolios: [],
                showPortfolioMobileDialog: false,
                preLastVisibleItem: null,
                preFirstInvisibleItem: null,
                visibleDataListId: 'visible-portoflios-list-id',
                invisibleDataListId: 'invisible-portoflios-list-id',
            }
        },

        computed: {
            showErrorMessage: {
                get() {return this.errorMessage !== null},
                set() {}
            },
            isMobile: {
                get() { return this.$vuetify.breakpoint.xsOnly },
                set() {}
            },
            isPortfolioPage() {
                return this.$route?.matched.some(match => 
                    match?.path == '/finance/portfolio/'
                )
            },
            tabIdDashboard() {
                let tab =  this.$route.params.tab_id
                if (tab === 'stocks') {
                    tab = 'portfolios'
                }
                return tab
            },
            individualAssetEnabled() {
                return this.$store.state.account.subscribedProductIds?.includes(subscribeProductKeys.individualAsset)
            },
            numberVisiblePortfolios () {
                let numberPortfolios = 5;

                switch (this.$vuetify.breakpoint.name) {
                    case 'md':
                        numberPortfolios = 3
                        break;
                    case 'lg':
                        numberPortfolios = 4
                        break;
                    case 'xl':
                        numberPortfolios = 5
                        break;
                    default:
                        break;
                }

                return numberPortfolios;
            },
            numberInvisiblePortfolios () {
                return this.invisiblePortfolios.length;
            },
            activePortfolioName() {
                if (this.$route.name === 'finance-dashboard-tab_id') {
                    return this.$t('component.portfolio.portfolioTabs.dashboard');
                }
                if (this.portfolioId && this.portfolios) {
                    return this.portfolios.find(p => p.id == this.portfolioId)?.name || '';
                }
                return '';
            },
            dashboardTabUrl() {
                let baseUrl = this.tabIdDashboard && !['portfolios', 'stocks', 'performance'].includes(this.tabIdDashboard) ?
                    `/finance/dashboard/${this.tabIdDashboard}/` :
                    '/finance/dashboard/'
                if (this.$route.query.lang) {
                    baseUrl = baseUrl + `?lang=${this.$route.query.lang}`
                }

                return baseUrl
            }
        },

        methods: {
            optionalOn(){
                if(this.portfolioId && this.portfolios.every(p => p.id.toString() !== this.portfolioId)) {
                    return true
                }
                return false
            },

            async getPortfolioTabs() {
                try {
                    this.portfolios = await this.portfolioService.getTabs();
                    this.$store.commit('preferences/setPortfolioTabs', this.portfolios)
                    this.sliderKey = !this.sliderKey
                } catch(e) {
                    this.handleBackendException(e, this.$t('component.portfolio.portfolioTabs.fetchError'))
                }
                this.updateSelectedTab()
            },

            async onDragEndCallback(event) {
                const sourceListId = event.from.getAttribute('data-list-id');
                const targetListId = event.to.getAttribute('data-list-id');

                if (sourceListId === this.invisibleDataListId && targetListId === this.visibleDataListId) {
                    this.invisiblePortfolios.unshift(this.preLastVisibleItem);
                    this.visiblePortfolios =
                        this.visiblePortfolios.filter(p => p.id != this.preLastVisibleItem.id);
                } else if (sourceListId === this.visibleDataListId && targetListId === this.invisibleDataListId) {
                    this.visiblePortfolios.push(this.preFirstInvisibleItem);
                    this.invisiblePortfolios =
                        this.invisiblePortfolios.filter(p => p.id != this.preFirstInvisibleItem.id);
                }

                const visiblePortfolioIds = this.visiblePortfolios.map(p => p.id);
                const invisiblePortfolioIds = this.invisiblePortfolios.map(p => p.id);
                
                try {
                    await this.portfolioService.setPositions([...visiblePortfolioIds, ...invisiblePortfolioIds])
                    this.errorMessage = null
                } catch(e) {
                    this.handleBackendException(e, this.$t('component.portfolio.portfolioTabs.saveOrderError'))
                }
            },

            updateSelectedTab() {
                if (!this.portfolioId) {
                    this.portfolioTab = '0'
                    return
                }
                this.portfolioTab = `${this.portfolioId}`
            },

            rewindSlider() {
                setTimeout(() => this.sliderKey = !this.sliderKey, 1000)
            },
        },

        created() {
            this.portfolioService = new PortfolioService(this.$axios)
            this.formatterService = new FormatterService(this.$axios);
            this.assetMethodsIcons = this.formatterService.getAssetMethodIcons();
            this.getPortfolioTabs()
            this.$root.$on('request-portfolio-tabs-refresh', this.getPortfolioTabs)
            this.$root.$on('request-rewind-slider', this.rewindSlider)
        },

        watch: {
            portfolioId() {
                this.updateSelectedTab()
            },
            portfolios(portfolios) {
                this.visiblePortfolios = portfolios.slice(0, this.numberVisiblePortfolios);
                this.invisiblePortfolios = portfolios.slice(this.numberVisiblePortfolios);
            },
            // this watcher will be triggered when resizing
            numberVisiblePortfolios(numberPortfolios) {
                if (this.portfolios.length) {
                    this.visiblePortfolios = this.portfolios.slice(0, numberPortfolios);
                    this.invisiblePortfolios = this.portfolios.slice(numberPortfolios);
                }
            },
            visiblePortfolios(_, oldItems) {
                if (oldItems.length > 0) {
                    this.preLastVisibleItem = oldItems[oldItems.length - 1]
                }
            },
            invisiblePortfolios(_, oldItems) {
                if (oldItems.length > 0) {
                    this.preFirstInvisibleItem = oldItems[0]
                }
            }
        },

        updated() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return;
            }
            // Dispatch a resize event to force the slider to show the navigation arrows when needed
            const wrapperWidth = this.$refs['v-tabs'].$el.querySelector('.v-slide-group__wrapper').clientWidth
            const contentWidth = this.$refs['v-tabs'].$el.querySelector('.v-slide-group__content').offsetWidth
            window.dispatchEvent(new Event('resize'));
            if (this.showArrows === true && contentWidth > wrapperWidth) {
                this.showArrows = 'desktop'
            }
        },

        beforeDestroy() {
            this.$root.$off('request-portfolio-tabs-refresh', this.getPortfolioTabs)
            this.$root.$off('request-rewind-slider', this.rewindSlider)
        },
    }
