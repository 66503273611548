
import subscriptionConstants from '@/constants/subscription.constants'
import FormatterService from '@/service/FormatterService';

const { subscribeProductKeys } = subscriptionConstants;

export default {
    name: "PortfolioTab",

    props: {
        portfolio: {
            type: Object,
            default: () => {}
        },
    },

    data() {
        return {
            assetMethodsIcons: null,
        }
    },

    computed: {
        individualAssetEnabled() {
            return this.$store.state.account.subscribedProductIds?.includes(subscribeProductKeys.individualAsset);
        },
        tabId() {
            return this.$route.params.tab_id;
        },
    },

    methods: {
        getTabUrl(portfolio) {
            if (!this.individualAssetEnabled && portfolio.portfolioType === 'asset') {
                return '/feature/individuelle-assets/';
            }

            const tabId = this.$route.params.tab_id;
            return tabId ? `/finance/portfolio/${portfolio.id}/${tabId}/` : `/finance/portfolio/${portfolio.id}/`
        }
    },

    created() {
        this.formatterService = new FormatterService();
        this.assetMethodsIcons = this.formatterService.getAssetMethodIcons();
    }
}
